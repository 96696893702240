<!--乡宁荟-->
<template>
    <div class="xiangninghui">
        <div class="banner">
            <el-carousel trigger="click" height="460px" arrow="never"  :interval="3000">
                    <el-carousel-item  >
                        <div class="banner1"></div>
                    </el-carousel-item>
                    <el-carousel-item  >
                        <div class="banner2"></div>
                    </el-carousel-item>
                    <el-carousel-item >
                        <div class="banner3"></div>
                    </el-carousel-item>
            </el-carousel>
        </div>
        <subnav :isactive="'/sanxiangguangbo'" />
        <div class="secondMenu">
            <div class="w-1400 clearfix">
                <div class="page-name fl">三农广播</div>
                <ul class="page-list fl">
                    <li class="page-item fl" :class="{act:pageOfsxdt.includes(pageName)}"><router-link to="/dynamic">三农动态</router-link> </li>
                    <li class="page-item fl" :class="{act:pageOfzchb.includes(pageName)}"><router-link to="/policy">政策红包</router-link> </li>
                    <li class="page-item fl" :class="{act:pageOfsjzx.includes(pageName)}"><router-link to="/datas">数据中心</router-link> </li>
                </ul>
            </div>
        </div>
        <router-view/>
    </div>
</template>
<script>
import subnav from '@/components/subnav'
export default {
    components:{
        subnav
    },
    data(){
        return{
            pageName:'ziyuanjishi',
            pageOfsxdt:['sanxiangguangboDynamic',],
            pageOfzchb:['sanxiangguangboPolify',],
            pageOfsjzx:['sanxiangguangboDatas']
        }
    },
    created(){
        this.pageName = this.$route.name
    },
    watch:{
        $route(a){
            this.pageName = a.name;
        }
    },
}
</script>
<style scoped>
.banner >>> .el-carousel__indicators--horizontal{
    left: 90%;
    bottom: 36px;
}
.banner >>> .el-carousel__button{
    height: 6px;
}
.act.page-item a{
    color: #f35c15;
    border-bottom:2px solid #f35c15;
}
.page-item:hover a{
    color: #f35c15;
    border-bottom:2px solid #f35c15;
}
.page-item{
    margin: 0 20px;
}
.page-name{
    width: 240px;
    color: #0a3695;  
}
.secondMenu{
    font-weight: 600;
    font-family: '微软雅黑';
    margin-top: 65px;;
    height: 60px;
    line-height: 60px;
    background: #eee;
    font-size: 20px;
}
.banner-title{
    height: 100px;
    line-height: 100px;
    width: 100%;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    font-size: 60px;
    text-align: center;
    font-family: '华文琥珀','幼圆','黑体';
    color:#fff;
    text-shadow: #0169ca 1px 0 0, #0169ca 0 1px 0, #0169ca -1px 0 0, #0169ca 0 -1px 0;
    /* font-weight: 600; */
}
.banner{
    position: relative;
    height: 460px;
}
.banner1,.banner2,.banner3{
    height: 100%;
    
    background-size: 100% 100%;
}
.banner1{background-image: url('../../assets/img/sanxiangguangbo-banner1.png') }
.banner2{background-image: url('../../assets/img/sanxiangguangbo-banner2.png') }
.banner3{background-image: url('../../assets/img/sanxiangguangbo-banner3.png') }
</style>